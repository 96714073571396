import { UserFunctionsEnum } from "../../app/models/enums/enums";
import { UserModel } from "../../app/models/interfaces/user-model";

//method to prepare date param for our PHP API
export function removeMillisecondsFromDateString(dateStr: string): string {
    let dateWithoutMillis = dateStr.split('.')[0];
    let lastChar = dateStr.charAt(dateStr.length - 1);
    return dateWithoutMillis + lastChar;
}

export function prepareUserFunctionsArray(user: UserModel): UserFunctionsEnum[] {
    let functions = user?.user_functions || [];
    if (user?.is_quasi_user) {
        functions = [UserFunctionsEnum.QUASI];
    }
    if (user?.has_learning_materials) {
        functions = [...functions, UserFunctionsEnum.LEARNING_MATERIALS];
    }
    return functions;
}

export function prepareDateApiParameter(date: Date): string {
    const MINUTES_IN_HOUR = 60;

    const dateString = date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    }).replace(' ', 'T').replace('kl. ', '');

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / MINUTES_IN_HOUR)).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset) % MINUTES_IN_HOUR).padStart(2, '0');
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';
    const formattedOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`;

    return `${dateString}${formattedOffset}`;
}
